<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>专项提升</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content flex">
        <div v-for="(item, index) in list" :key="index" class="con-item" @click="jump(item.jumpUrl)">
          <div class="img" :style="`background:url(${item.coverImg});background-size: cover;`" />
          <div style="margin-top: 20px; font-size: 18px">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import img0 from "../../assets/img/ascension/game0.png";
import img1 from "../../assets/img/ascension/game1.png";
export default {
  //专项提升
  data() {
    return {
      img0: img0,
      img1: img1,
      list: [],
    };
  },
  methods: {
    getCourseList() {
      var param = {};
      this.$api.user.getSpecialList(param).then((res) => {
        if (res.data.code === 200) {
          this.list = res.data.data;
        }
      });
    },
    jump(url) {
      window.open(url);
    },
  },
  created() {
    this.getCourseList();
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 28px;
  height: 310px;
  background: #f9fbfc;
  padding: 30px;
  .con-item {
    margin-right: 20px;
  }
  .img {
    width: 300px;
    height: 200px;
  }
}
</style>
